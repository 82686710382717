.favorite-photos {
	background-color: #000;
	&__title {
		padding: 35px 0;
		font-size: 30px;
		color: #fff;
		text-align: center;
		font-weight: 900;
		line-height: 1;
		@include respond-to('medium') {
			padding: 20px 0;
		}
	}
	&__slider {
		position: relative;
	}
}

.favorite-photo {
	display: block;
	height: 400px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	@include respond-to('extra-small') {
		height: 350px	;
	}
	@include respond-to('mobile-l') {
		height: 300px	;
	}
}

.slider-arrows {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 100%;
	height: 51px;
	pointer-events: none;
	@include respond-to('small') {
		// display: none;
	}
	& button {
		pointer-events: all;
		background: none;
		border: none;
	}
	&__left {
		position: absolute;
		left: 90px;
		@include respond-to('medium') {
			left: 30px;
		}
	}
	&__right {
		position: absolute;
		right: 90px;
		@include respond-to('medium') {
			right: 30px;
		}
	}
}