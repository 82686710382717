.wrapper-banners {
	position: relative;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	// @include respond-to('huge') {
	// 	height: 650px;
	// }
	// @include respond-to('large') {
	// 	height: 450px;
	// }
	// @include respond-to('extra-small') {
	// 	height: 300px;
	// }
	@include respond-to('medium') {
		min-height: 100vh;
		height: auto;
	}
}

.wrap-banner-scroll {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include respond-to('medium') {
		position: static;
	}
}

.banner-item {
	width: 100%;
	min-height: 100vh;
	position: relative;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	&::after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($color: #000000, $alpha: 0.3);
	}
	& .section-limit {
		height: 100vh;
		position: relative;
	}
	&__title {
		position: absolute;
		z-index: 1;
		top: 50%;
		right: 0 !important;
		transform: translateY(-50%);
		max-width: 600px;
		width: 100%;
		padding: 0 15px;
		color: $title;
		font-size: 50px;
		font-weight: 700;
		line-height: 0.9;
		@include respond-to('extra-small') {
			top: auto;
			bottom: 25px;
			transform: none;
			font-size: 40px;
		}
	}
}

.banner-form {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 140px;
	margin: 0 auto;
	max-height: 91px;
	@include respond-to('medium') {
		max-height: unset;
		// bottom: 0;
		position: static;
		background-color: #000;
	}
}

#itemGallery .lg-thumb ,.lg-outer ,#itemGallery {
    direction: ltr; 
}

.banner-item .section-limit{
	padding: 0 115px;
}