.wrapper-all-content {
	.wrap-content_p-0 {
		padding-bottom: 0;
	}
}

.product-description-wrap {
	margin-top: 25px;
	padding-bottom: 25px;
	@include respond-to('large') {
		margin-top: 15px;
		padding-bottom: 15px;
	}
	@include respond-to('medium') {
		margin-top: 0;
		padding-bottom: 0;
	}
}

.product-code {
	margin-bottom: 35px;
	font-weight: 700;
	font-size: 20px;
	@include respond-to('medium') {
		margin-bottom: 20px;
	}
}

.product-item-description {
	&__text {
		margin-bottom: 55px;
		@include respond-to('medium') {
			margin-bottom: 30px;
		}
		& p {
			font-size: 20px;
			font-weight: 300;
			margin: 0;
			@include respond-to('small') {
				font-size: 18px;
				text-align: justify;
			}
			@include respond-to('extra-small') {
				font-size: 16px;
			}
		}
	}
	&__number {
		width: 280px;
		height: 77px;
		border: 1px solid orange;
		text-align: center;
		box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
		@include respond-to('extra-small') {
			margin: 0 auto;
			width: 255px;
			height: 60px;
		}
		& a {
			display: block;
			padding: 25px 0;
			line-height: 1;
			font-size: 25px;
			font-weight: 500;
			@include link_no-hover(#000);
			@include respond-to('extra-small') {
				padding: 18px 0;
				font-size: 22px;
			}
		}
	}
}

.product-item-gallery-block {
	position: relative;
	text-align: $left;
	@include respond-to('medium') {
		text-align: center;
		margin: 0 auto 20px;
		max-width: 450px;
	}
}

.product-item-gallery {
	// max-width: 620px;
	padding: 30px;
	float: $left;
	position: relative;
	border: 1px solid #000;
	overflow: hidden;
	text-align: center;
	@include respond-to('1100') {
		padding: 15px;
	}
	@include respond-to('medium') {
		float: none;
		display: block;
	}
	& img {
		display: block;
		max-width: 100%;
		margin: 0 auto;
	}
}

.label-zoom {
	display: block;
	position: absolute;
	bottom: 30px;
	left: 30px;
	width: 84px;
	height: 93px;
	background-image: url(../images/item-zoom.png);
	background-size: cover;
	z-index: 2;
	@include respond-to('1100') {
		bottom: 15px;
		left: 15px;
	}
}

.centeredtext {
	opacity: 0.5;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
  }

//   .lg-img-wrap .lg-object.lg-image{
// 	position: relative;
//   }
//   .lg-img-wrap::after{
// 	opacity: 0.3;
// 	position: absolute;
// 	top: 50%;
// 	left: 50%;
// 	transform: translate(-50%, -50%);
// 	content: url(../images/logo.png);
//   }
//   .lg-thumb-item::after{
// 	opacity: 0.3;
// 	position: absolute;
// 	top: 50%;
// 	left: 50%;
// 	transform: translate(-50%, -50%);
// 	content: url(../images/logo.png);
//   }

// .lg-thumb-outer{
// 	height: 112px;
// }

//   .lg-sub-html {
// 	bottom: 40px;
// 	color: #ffffff;
// 	font-size: 20px;
// 	font-weight: 400;
// }