.other-photos {
	background-color: #fff;
	border-top: 1px solid #fff;
	border-bottom: 1px solid #fff;
	&__slider {
		position: relative;
	}
}

.other-photo {
	display: block;
	height: 200px;
	// min-width: 200px;
	max-width: 200px; 
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	&:nth-child(odd) {
		margin: 0 1px;
	}
}

.slider-arrows {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 100%;
	height: 51px;
	pointer-events: none;
	@include respond-to('small') {
		// display: none;
	}
	& button {
		pointer-events: all;
		background: none;
		border: none;
	}
	&__left {
		position: absolute;
		left: 90px;
		@include respond-to('medium') {
			left: 30px;
		}
	}
	&__right {
		position: absolute;
		right: 90px;
		@include respond-to('medium') {
			right: 30px;
		}
	}
}


.lg-outer .lg-thumb-item{
	height: 100px;
}