footer {
	position: relative;
	width: 100%;
	padding: 50px 0 10px;
	z-index: 1;
	background-color: #000;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	box-shadow: 0 0 50px rgba(255, 255, 255, 0.25);
	@include respond-to('large') {
		padding: 20px 0 10px;
	}
}

.ftr-wrap {
	&__right {
		width: 22%;
		float: $right;
		@include respond-to('1100') {
			float: none;
			width: 100%;
			max-width: 250px;
			margin: 0 auto 15px;
			text-align: center;
		}
	}
	&__left {
		width: 78%;
		float: $left;
		@include respond-to('1100') {
			float: none;
			width: 100%;
		}
	}
}

.link-col {
	float: $right;
	width: 25%;
	@include respond-to('small') {
		width: 50%;
	}
}

.hr-footer-bottom {
	border: 0;
	border-top: 1px solid #d9d9d9;
	margin-top: 20px;
	margin-bottom: 20px;
	@include respond-to('medium') {
		margin-top: 20px;
	}
	@include respond-to('extra-small') {
		margin-top: 10px;
	}
}

.ftr-logo {
	// text-align: center;
	@include respond-to('medium') {
		text-align: center;
	}
	& img {
		max-width: 216px;
		@include respond-to('medium') {
			// max-width: 150px;
		}
	}
}

.ftr-contact {
	padding-top: 40px;
	padding-#{$left}: 15px;
	@include respond-to('1100') {
		max-width: 250px;
		margin: 10px auto 10px;
		padding-top: 15px;
		padding-#{$left}: 0px;
	}
	&__item {
		margin-bottom: 15px;
		color: #fff;
		@include respond-to('medium') {
			margin-bottom: 5;
		}
		& i {
			display: inline-block;
			vertical-align: middle;
			width: 15%;
			margin-#{$left}: -4px;
			color: $accent;
			font-size: 22px;
		}
		& span {
			display: inline-block;
			vertical-align: middle;
			padding-#{$right}: 4px;
			width: 85%;
			font-size: 18px;
			font-weight: 300;
			@include respond-to('medium') {
				// font-size: 15px;
				// font-weight: 300;
			}
		}
	}
}

.ftr-soc {
	margin-top: 25px;
}