.wrapper-all-content {
	position: relative;
	width: 100%;
	padding: 30px 0;
	background-color: #fff;
	@include respond-to('small') {
		padding: 15px 0;
	}
	&_black {
		background-color: #000;
	}
	&_pt15 {
		padding-top: 13px;
	}
	&_pt0 {
		padding-top: 0;
	}
}

.category-block {
	margin: 0 -13px;
	padding-bottom: 15px;
	font-size: 0;
	@include respond-to('large') {
		text-align: center;
	}
	@include respond-to('small') {
		text-align: center;
	}
	@include respond-to('extra-small') {
		margin: 0;
	}
	&_404 {
		& .category-block__wrap {
			width: 50%;
			height: 580px;
			@include respond-to('huge') {
				height: 500px;
			}
			@include respond-to('1100') {
				height: 400px;
			}
			@include respond-to('small') {
				width: 100%;
			}
			@include respond-to('extra-small') {
				height: 350px;
			}
			& .additional-info__text {
				top: 56%;
			}
			&:nth-child(1) {
				width: 33.33%;
				height: 456px;
				@include respond-to('huge') {
					height: 376px;
				}
				@include respond-to('1100') {
					height: 400px;
					width: 50%;
				}
				@include respond-to('small') {
					width: 100%;
					max-width: 500px;
				}
				@include respond-to('extra-small') {
					height: 350px;
					padding: 7px 13px;
				}
				& .additional-info__text {
					top: 58%;
				}
			}
			&:nth-child(2) {
				width: 33.33%;
				height: 456px;
				@include respond-to('huge') {
					height: 376px;
				}
				@include respond-to('1100') {
					height: 400px;
					width: 50%;
				}
				@include respond-to('small') {
					width: 100%;
					max-width: 500px;
				}
				@include respond-to('extra-small') {
					height: 350px;
					padding: 7px 13px;
				}
				& .additional-info__text {
					top: 58%;
				}
			}
			&:nth-child(3) {
				width: 33.33%;
				height: 456px;
				@include respond-to('huge') {
					height: 376px;
				}
				@include respond-to('1100') {
					height: 400px;
					width: 50%;
				}
				@include respond-to('small') {
					width: 100%;
					max-width: 500px;
				}
				@include respond-to('extra-small') {
					height: 350px;
					padding: 7px 13px;
				}
				& .additional-info__text {
					top: 58%;
				}
			}
		}
	}
	&__wrap {
		display: inline-block;
		width: 33.33%;
		height: 456px;
		padding: 13px;
		font-size: initial;
		vertical-align: top;
		@include respond-to('huge') {
			height: 376px;
		}
		@include respond-to('1100') {
			height: 400px;
			width: 50%;
		}
		@include respond-to('small') {
			width: 100%;
			max-width: 500px;
		}
		@include respond-to('extra-small') {
			height: 350px;
			padding: 7px 13px;
		}
		&:nth-child(5n + 4) {
			width: 50%;
			height: 580px;
			@include respond-to('huge') {
				height: 500px;
			}
			@include respond-to('1100') {
				height: 400px;
			}
			@include respond-to('small') {
				width: 100%;
			}
			@include respond-to('extra-small') {
				height: 350px;
			}
			& .additional-info__text {
				top: 56%;
			}
		}
		&:nth-child(5n + 5) {
			width: 50%;
			height: 580px;
			@include respond-to('huge') {
				height: 500px;
			}
			@include respond-to('1100') {
				height: 400px;
			}
			@include respond-to('small') {
				width: 100%;
			}
			@include respond-to('extra-small') {
				height: 350px;
			}
			& .additional-info__text {
				top: 56%;
			}
		}
		&_about {
			float: $right;
			@include respond-to('large') {
				display: block;
				float: none;
				width: 100% !important;
				max-width: 70%;
				margin: 0 auto;
				height: auto !important;
			}
			@include respond-to('medium') {
				max-width: 90%;
			}
			@include respond-to('small') {
				float: none;
				max-width: 95%;
			}
			@include respond-to('extra-small') {
				max-width: 100%;
			}
		}
	}
	&__item {}
}

.cat-box-item {
	position: relative;
	overflow: hidden;
	display: block;
	width: 100%;
	height: 100%;
	border: 2px solid #fff;
	@include link_no-hover(#fff);
	&:hover {
		& .additional-info {
			opacity: 1;
		}
		& .cat-box-item__title {
			bottom: -100%;
		}
	}
	&__photos {}
	&__title {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		background-color: rgba($color: #000, $alpha: 0.8);
		&.title {
			padding: 19px 0;
			@include respond-to('large') {
				font-size: 25px;
			}
			@include respond-to('extra-small') {
				padding: 10px;
			}
		}
	}
	&__additional-info {}
}

.cat-item-photos {
	width: 100%;
	height: 100%;
	font-size: 0;
	&__item {
		display: inline-block;
		width: 50%;
		height: 50%;
		background-origin: content-box;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		font-size: initial;
		@include respond-to('mobile-l') {
			width: 100%;
			height: 50%;
		}
		&:first-child {
			border-#{$left}: 1px solid #000;
			border-bottom: 2px solid #000;
			@include respond-to('mobile-l') {
				border-#{$left}: none;
			}
		}
		&:nth-child(2) {
			border-#{$right}: 2px solid #000;
			border-bottom: 2px solid #000;
			@include respond-to('mobile-l') {
				display: none;
			}
		}
		&:nth-child(3) {
			border-#{$left}: 1px solid #000;
			border-top: 1px solid #000;
			@include respond-to('mobile-l') {
				border-#{$left}: none;
			}
		}
		&:nth-child(4) {
			border-#{$right}: 2px solid #000;
			border-top: 1px solid #000;
			@include respond-to('mobile-l') {
				display: none;
			}
		}
		&_one {
			display: block;
			width: 100%;
			height: 100%;
			border: none;
			&:first-child {
				border: none;
			}
		}
	}
}

.additional-info {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba($color: #000000, $alpha: 0.8);
	opacity: 0;
	transition: opacity 0.3s ease;
	&__text {
		position: absolute;
		top: 58%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-width: 90%;
		width: 100%;
	}
	&__title {
		position: relative;
		padding-bottom: 10px;
		font-size: 30px;
		font-weight: 900;
		text-align: center;
		@include respond-to('large') {
			font-size: 25px;
		}
		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			margin: 0 auto;
			height: 1px;
			max-width: 378px;
			width: 100%;
			background-color: #fff;
		}
	}
	&__desc {
		padding-top: 10px;
		& p {
			margin: 0;
			color: #fff;
			font-size: 20px;
			font-weight: 700;
			text-align: center;
			@include respond-to('large') {
				font-size: 18px;
			}

		}
	}
	&__plus {
		position: relative;
		width: 40px;
		height: 40px;
		margin: 40px auto 0;
		border: 1px solid #fff;
		@include respond-to('large') {
			margin-top: 20px;
		}
		&::after,
		&::before {
			content: '';
			display: block;
			// position: absolute;
			// left: 50%;
			// top: 50%;
			// transform: translate(-50%, -50%);
			background-color: #fff;
		}
		&::after {
			width: 23px;
			height: 1px;
			margin-right: 7px;
			margin-top: -12px;
		}
		&::before {
			width: 1px;
			height: 23px;
			margin-right: 18px;
			margin-top: 8px;
		}
		&_subcat {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			margin: 0;
		}
	}
}

.about-box {
	height: 100%;
	overflow: hidden;
	padding: 55px 78px;
	border: 2px solid #fff;
	background-position: top right -50px;
	background-repeat: no-repeat;
	@include respond-to('1650') {
		padding: 30px;
	}
	@include respond-to('huge') {
		background-size: contain;
	}
	@include respond-to('small') {
		padding: 15px;
		background-image: none !important;
	}
	&__text-block {
		float: $left;
	}
	&__photo {
		display: none;
		text-align: center;
		max-height: 300px;
		margin-bottom: 20px;
		@include respond-to('small') {
			display: inline-block;
		}
		& img {}
	}
}

.about-text {
	max-width: 493px;
	width: 100%;
	color: #fff;
	@include respond-to('1550') {
		max-width: 400px;
	}
	@include respond-to('small') {
		max-width: 100%;
	}
	&__title {
		margin-bottom: 45px;
		text-align: right;
		font-size: 40px;
		font-weight: 800;
		line-height: 1;
		@include respond-to('1550') {
			margin-bottom: 25px;
		}
		@include respond-to('huge') {
			margin-bottom: 15px;
			font-size: 30px;
		}
		@include respond-to('extra-small') {
			font-size: 25px;
		}
	}
	&__desc {
		& p {
			margin: 0;
			font-size: 20px;
			font-weight: 300;
			text-align: justify;
			line-height: 1.3;
			@include respond-to('huge') {
				font-size: 18px;
			}
			@include respond-to('extra-small') {
				font-size: 16px;
			}
		}
	}
	&__link {
		width: 100px;
		float: $left;
		margin-top: 50px;
		font-size: 20px;
		font-weight: 700;
		@include link_no-hover(#fff);
		@include respond-to('1550') {
			margin-top: 25px;
		}
	}
}
// @media screen and (max-width: 1500px) and (min-width: 4400px){
// 	.about-text{
// 		max-width: 337px; 
// 	}  
// 	.about-text__desc p {
// 		margin: 0;
// 		font-size: 18px;
// 	}
// }
@media screen and (max-width: 1750px) {
	.about-text {
		max-width: 439px;
	}
}

@media screen and (max-width: 1501px) {
	.about-text{
		max-width: 337px; 
	}  
	.about-text__desc p {
		margin: 0;
		font-size: 18px;
	}
}
@media screen and (max-width: 1270px) {
	.about-text{
		max-width: 308px;
	}  

}


