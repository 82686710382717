.banner-top-content {
	position: relative;
	width: 100%;
	min-height: 370px;
	max-height: 45vh;
	@include respond-to('small') {
		// min-height: 500px;
	}
	&::after {
		content: ''; 
		position: absolute;
		left: 0;
		top: 0; 
		width: 100%;
		height: 100%;
		background-color: rgba($color: #000000, $alpha: 0.25);
	} 
}
 
.banner-top-content__image {
	background-position: center center;
	background-size: cover;
	height: 370px;
	@include respond-to('small') {
		// height: 500px;
	}
}

.banner-title-block {
	position: absolute;
	z-index: 100;
	top: 68%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 1430px;
	width: 100%;
	padding: 0 15px;
	@include respond-to('huge') {
		top: auto;
		bottom: 0;
		transform: translate(-50%, 0);
	}
	@include respond-to('small') {
		bottom: 25px;
	}
	&__title {
		display: inline-block;
		margin: 0;
		padding-bottom: 20px;
		border-bottom: 2px solid #fff;
		line-height: 1;
		font-size: 50px;
		font-weight: 900;
		color: #fff;
		@include respond-to('small') {
			font-size: 40px;
			padding-bottom: 10px;
		}
		@include respond-to('extra-small') {
			font-size: 35px;
		}
	}
	&__breadcrumbs {}
}

@media screen and (max-width: 992px) {
	.banner-top-content{
		min-height: 280px;
	}
	.banner-top-content__image{
		height: 280px;
	}
}

