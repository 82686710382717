.modal {
    z-index: 999999999999;
}
._csform{
    margin: 15px 0 !important;
}

.popupform label{
    display: none;
}
.btn , .form-control, .modal-content{
    border-radius: 0 !important;
} 

.modal-dialog{
    margin: 15% auto;
}

#nameimage{
    font-size: 19px;
    font-weight: 400; 
}
@media screen and (min-width: 992px) {
    .mgright{
        margin-right: 8.3%;
    }
}
@media screen and (max-width: 992px) {
    #nameimage{
        display: block;
    }
}
