.wrap-content {
	padding: 20px 0;
	@include respond-to('small') {
		padding: 0;
	}
	&__content {
		& > .section-limit {
			padding-bottom: 25px;
		}
	}
	&_pt30 {
		padding-top: 30px;
	}
}

.title {
	padding: 0 0 20px;
	font-size: 30px;
	font-weight: 900;
	line-height: 1;
	color: $title;
	text-align: center;
	&_404 {
		padding-bottom: 40px;
		padding-top: 20px;
		padding-left: 15px;
		padding-right: 15px;
		@include respond-to('small') {
			padding-bottom: 25px;
		}
		& span {
			display: block;
			font-size: 25px;
			line-height: 1;
			font-weight: 400;
		}
	}
}

.content-text {
	h2 {
		margin: 0 0 35px;
		font-weight: 300;
		font-size: 40px;
		color: #000;
		@include respond-to('1100') {
			font-size: 30px;
			margin-bottom: 20px;
		}
		@include respond-to('small') {
			font-size: 25px;
		}
	}
	h3 {
		margin: 0 0 35px;
		font-weight: 300;
		font-size: 26px;
		color: #000;
		@include respond-to('1100') {
			font-size: 22px;
			margin-bottom: 20px;
		}
		@include respond-to('small') {
			font-size: 20px;
		}
	}
	p {
		font-weight: 300;
		font-size: 18px;
		color: #000;
		// margin-bottom: 25px;
		@include respond-to('small') {
			font-size: 16px;
			text-align: justify;
			// margin-bottom: 15px;
		}
	}
	iframe {
		@include respond-to('small') {
			text-align: center !important;
			margin: 15px auto !important;
			width: 100% !important;
			height: auto !important;
			float: none !important;
			display: block !important;
		}
	}
	img {
		@include respond-to('small') {
			float: none !important;
			text-align: center !important;
			width: auto !important;
			height: auto !important;
			margin: 15px auto !important;
		}
	}
}

.content {
	&_right {
		// padding-#{$left}: 0px;
		text-align: center;
	}
	&_left {
		// padding-#{$right}: 30px;
		// @include respond-to('large') {
		// 	padding-#{$right}: 15px;
		// }
		// @include respond-to('small') {
		// 	padding: 0;
		// }
	}
}