.side-form-container {
	position: fixed;
	top: 0;
	#{$left}: -245px;
	height: 100vh;
	max-height: 1000px;
	width: 245px;
	z-index: 101;
	background-color: transparent;
	transition: left 0.5s ease;
	&_open {
		#{$left}: 0;
	}
}

.side-form {
	width: 100%;
	height: 100%;
	&__button {
		position: absolute;
		// #{$right}: -99px;
		#{$right}: -89px;
		top: 43%;
		z-index: 1;
		@include respond-to('extra-small') {
			#{$right}: -60px;
		}
	}
	&__form-body {
		position: absolute;
		#{$left}: 0;
		top: 0;
		max-width: 245px;
		width: 100%;
	

		height: 100%;
		background-color: #000;
	}
}

.side-btn {
	width: 90px;
	// height: 60px;
	// padding: 10px 0 8px;
	border-top-#{$right}-radius: 50px;
	border-bottom-#{$right}-radius: 50px;
	box-shadow: 8px 5px 10px 0 rgba(0, 0, 0, 0.1), 8px -5px 10px 0 rgba(0, 0, 0, 0.1);
	cursor: pointer;
	background-color: #fff;
	transition: all 0.3s ease;
	@include respond-to('extra-small') {
		width: 60px;
	}
	&_close {
		background-color: #000;
		border-top: 1px solid #484848;
		border-#{$right}: 1px solid #484848;
		border-bottom: 1px solid #484848;
		width: 60px;
		height: 80px;
		#{$right}: -59px;
		@include respond-to('extra-small') {
			width: 60px;
			height: 76px;
			// #{$right}: -60px;
		}
		& .side-btn__text {
			padding-top: 16px;
			@include respond-to('extra-small') {
				padding-top: 14px;
			}
		}
		& .show-text {
			display: none;
		}
	}
	&__icon {
		display: none;
		width: 28px;
		height: 34px;
		vertical-align: middle;
		& i {
			color: $accent;
			font-size: 45px;
			line-height: 0.7;
		}
	}
	&__text {
		display: inline-block;
		width: 100%;
		vertical-align: middle;
		padding-#{$right}: 16px;
		padding-top: 5px;
		padding-bottom: 5px;
		text-align: $right;
	}
}

.hide-text {
	display: none;
	max-width: 95px;
	width: 100%;
	color: #7a7b88;
	font-size: 20px;
	font-weight: 700;
	line-height: 1;
	&__icon {
		display: inline-block;
		width: 38%;
		color: $accent;
		font-size: 45px;
		line-height: 0.7;
	}
	&__text {
		display: inline-block;
		width: 55%;
		@include respond-to('extra-small') {
			display: none;
		}
	}
	&_show {
		display: inline-block;
	}
}

.show-text {
	display: inline-block;
	// max-width: 55px;
	text-align: $right;
	padding: 10px 0;
	@include respond-to('extra-small') {
		// max-width: 45px;
		font-size: 14px;
		padding: 12px 0;
	}
	&__icon {
		float: $right;
		width: 30%;
		margin-top: 11px;
		font-size: 24px;
		@include respond-to('extra-small') {
			width: 100%;
			margin-top: 7px;
		}
		& i {
			display: block;
			margin: 0 auto;
			text-align: center;
		}
	}
	&__text {
		width: 70%;
		float: $left;
		padding-#{$right}: 10px;
		color: #000;
		font-size: 16px;
		font-weight: 800;
		line-height: 1.2;
		@include respond-to('extra-small') {
			display: none;
		}
	}
}

.form-body {
	border-#{$right}: 1px solid #484848;
	box-shadow: 5px 0 20px 0 rgba(0, 0, 0, 0.15);
	padding-bottom: 50px;
	&__head {
		padding: 10px 15px;
		border-bottom: 1px solid #484848;
		height: 110px;
		text-align: center;
		@include respond-to('small') {
			padding: 6px 15px;
			height: 100px;
		}
		@include respond-to('extra-small') {
			padding: 3px 10px;
		}
	}
	&__head-logo {
		display: inline-block;
		padding-top: 20px;
		& img {
			// margin-top: 10px;
			// max-width: 200px;
			@include respond-to('small') {
				// max-width: 135px;
			}
		}
	}
	&__main {
		height: calc(100% - 140px);
		overflow-y: auto;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		@include respond-to('small') {
			height: calc(100% - 100px);
		}
		&::-webkit-scrollbar {
			// Safari and Chrome
			display: none;
		}
		-ms-overflow-style: none; // IE 10+
		overflow: -moz-scrollbars-none; // Firefox
		// @include respond-to('larger') {
		// 	height: calc(100% - 92px);
		// }
		// @include respond-to('medium') {
		// 	height: calc(100% - 62px);
		// }
	}
	&__contact {}
	&__form {
		padding: 35px 15px 25px;
		@include respond-to('extra-small') {
			padding: 20px 10px 25px;
		}
	}
	&__soc-block {
		height: 100px;
		
		border-top: 1px solid #484848;
		border-bottom: 1px solid #484848;
		text-align: center;
		background-color: #fff;
		& a {
			min-height: 95px;
			display: block;
			padding: 15px 0;
			float: $right;
			width: 50%;
			height: 100%;
			@include link_no-hover(#000);
			&:first-child {
				border-#{$left}: 1px solid #b8b8b8;
			}
			& img {
				max-width: 20px;
			}
			& p {
				max-width: 80px;
				margin: 8px auto 0;
				font-size: 16px;
				font-weight: 400;
				line-height: 1;
			}
		}
	}
}

.form-contact {
	display: block;
	width: 100%;
	margin: 0 auto;
	padding: 20px 15px;
	border-bottom: 1px solid #484848;
	text-align: center;
	@include respond-to('extra-small') {
		padding: 10px 10px;
	}
	&:hover {
		background-color: #232323;
	}
	&__title {
		float: $left;
		width: 82%;
		color: #fff;
		font-size: 18px;
		font-weight: 300;
		line-height: 1;
	}
	&__phone {
		float: $left;
		width: 82%;
		color: $title;
		font-size: 25px;
		font-weight: 800;
		line-height: 1;
		direction: ltr;
		@include link_no-hover(#fff);
		@include respond-to('extra-small') {
			font-size: 22px;
		}
	}
	&__icon {
		float: $right;
		width: 18%;
		margin-top: 10px;
		& img {
			@include respond-to('extra-small') {
				max-width: 45px;
			}
		}
	}
	&__mail {
		font-weight: 800;
		@include link_no-hover(#fff);
	}
}

.form-form {
	// max-width: 210px;
	width: 100%;
	&__title {
		text-align: center;
		& p {
			margin: 0;
			color: #fff;
			font-size: 20px;
			font-weight: 800;
			line-height: 1.1;
		}
		& p:first-child {
			color: $accent;
			font-weight: 300;
			font-size: 16px;
		}
	}
	&__fields {
		padding-top: 30px;
		@include respond-to('extra-small') {
			padding-top: 10px;
		}
	}
	& .form-group {
		margin-bottom: 11px;
	}
	& .row-3-form__input {
		margin-bottom: 0;
		width: 100%;
		// max-width: 210px;
		border: none;
		border-radius: 4px;
		padding: 7px 10px;
		font-size: $font-size-base;
		font-weight: 300;
		color: #9f9f9f !important;
		background-color: #f6f6f6;
		&:focus {
			&::placeholder {
				opacity: 0;
			}
		}
		&::placeholder {
			font-weight: 300;
			font-size: $font-size-base;
			color: #9f9f9f !important;
			transition: 0.3s;
		}
		&:-webkit-autofill {
			box-shadow: 0 0 100px 100px #fff inset;
		}
	}
	& textarea.row-3-form__input {
		height: 120px;
		resize: none;
		border-bottom: 1px solid #e7e7e9;
	}
	& .form-control {
		border-radius: 4px;
		height: auto;
		box-shadow: none;
	}
	& .help-block {
		margin: 0 0 5px;
		& ul {
			margin: 0;
			font-size: $font-size-small;
		}
	}
	& .required-info {
		font-size: 12px;
		color: #9f9f9f;
	}
	& .row-3-form__button {
		width: 100%;
		padding: 7px 20px;
		float: none;
		border: none;
		border-radius: 4px;
		color: #000;
		background-color: $accent;
		font-size: 18px;
		font-weight: 800;
	}
}