.cont-right {
	// padding-#{$left}: 15px;
	width: 33%;
	float: $right;
	@include respond-to('1100') {
		padding-#{$left}: 25px;
	}
	@include respond-to('medium') {
		max-width: 700px;
		width: 100%;
		margin: 0 auto;
		float: none;
		border-#{$left}: none;
		padding: 0;
	}
}

.cont-center {
	width: 35%;
	float: $right;
	border-#{$left}: 1px solid #d6d6d6;
	padding-#{$right}: 50px;
	padding-#{$left}: 15px;
	@include respond-to('medium') {
		padding-#{$right}: 20px;
		padding-top: 7px;
	}
}

.cont-left {
	padding-top: 20px;
	padding-#{$right}: 50px;
	width: 32%;
	float: $left;
	@include respond-to('1100') {
		padding-#{$right}: 25px;
	}
	@include respond-to('medium') {
		max-width: 400px;
		width: 100%;
		padding-#{$right}: 0;
		margin: 0 auto;
		float: none;
	}
}

.contact-desc {
	&__title {
		margin-bottom: 20px;
		font-size: 24px;
		font-weight: 800;
		color: #000;
		line-height: 1.2;
		@include respond-to('small') {
			font-size: 20px;
		}
	}
	&__desc {
		p {
			max-width: 370px;
			font-size: 18px;
			font-weight: 300;
			margin: 0;
			line-height: 1.2;
		}
	}
}

.contact-us {
	width: 100%;
	margin-top: 45px;
	@include respond-to('small') {
		max-width: none;
		width: 50%;
		padding-#{$right}: 15px;
	}
	@include respond-to('extra-small') {
		width: 100%;
		float: none;
		padding-top: 20px;
		padding-#{$right}: 0;
	}
	&__title {
		margin-bottom: 15px;
		font-size: 24px;
		font-weight: 800;
		color: #000;
		line-height: 1.2;
		@include respond-to('small') {
			font-size: 20px;
		}
	}
	&__icon {
		margin-bottom: 11px;
		& img {
			margin-#{$left}: 14px;
		}
	}
}

.contact-map {
	clear: both;
	padding-top: 55px;
	width: 100%;
	@include respond-to('medium') {
		padding-top: 30px;
	}
}

@media screen and (max-width: 992px) {
	.cont-center{
		width: 100%;
	}
}


