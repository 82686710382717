.form-horizontal {
	// margin: 0px 0 20px;
	margin: 0;
	padding: 25px;
	// max-width: 1430px;
	background-color: rgba($color: #000000, $alpha: 0.7);
	text-align: center;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
	@include respond-to('medium') {
		// margin: 0 0 10px;
	}
	@include respond-to('small') {
		// margin: 0 0 25px;
	}
	@include respond-to('extra-small') {
		// margin: 0 0 15px;
		padding: 15px;
	}
	& .form-group {
		display: inline-block;
		vertical-align: top;
		margin: side-values(0 0 0 20px);
		max-width: 23%;
		min-width: 190px;
		width: 100%;
		height: 41px;
		font-size: 16px;
		font-weight: 400;
		@include respond-to('huge') {
			float: none;
			display: inline-block;
			width: 18%;
		}
		@include respond-to('1100') {
			margin: 5px;
		}
		&_ml {
			margin-#{$left}: 0;
			@include respond-to('extra-small') {
				margin-#{$left}: 5px;
			}
		}
	}
	& .newslatter-input {
		width: 100%;
		height: 100%;
		padding: 5px 10px;
		max-height: 41px;
		font-size: 16px;
		border: none;
		// background-color: #fff;
		&::placeholder {
			font-size: 16px;
		color: #000;
		}
	}
	& .newslatter-btn {
		float: $left;
		margin: 0;
		padding: 7px 5px;
		height: 41px;
		// max-width: 190px;
		width: 100%;
		border: none;
		background-color: #fff;
		color: #000;
		font-size: 20px;
		font-weight: 900;
	}
}
.form-horizontal__form{
	max-width: 1430px;
    margin: 0 auto;
    padding: 0 105px; 
}


.bootstrap-select.btn-group .dropdown-toggle .caret{
	left: 3px;
	right: auto;
    margin-top: -3px;
}
.bootstrap-select.btn-group .dropdown-toggle .filter-option{
	text-align: $right;
}
.search-group .btn{
	border-radius: 0px;
}
.btn-success:focus , .btn-success{
	background: #000;
	outline: none;
	border: 1px #000;
}
.btn-success:hover{
	background: #353535;
	border: 1px #000;

}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret{
	border-bottom: none !important;
}
.caret {
    display: inline-block !important;
    width: 0 !important;
    height: 0 !important;
    margin-left: 2px !important;
    vertical-align: middle !important;
    border-top: 6px dashed !important;
    border-top: 4px solid\9 !important;
    border-right: 3px solid transparent !important;
    border-left: 3px solid transparent !important;
}



@media screen and (min-width: 992px) {
	.fhsearch .btn {
		padding: 9px 12px; 
		margin-top: -5px;
		font-size: 16px;
	} 
	.banner-item__title{
		margin-right: 99px;
	}
}

@media screen and (max-width: 992px) {
	.form-horizontal__form{
		padding: 0 15px;
	} 
	.fhsearch .btn {
		padding: 9px 12px; 
		// margin-top: -5px;   
		font-size: 16px;  
	} 
	.form-horizontal .fhsearch .newslatter-input{
		padding: 0;
	}
}

.form-horizontal .newslatter-btn , .product-item-description__number , .custom-form .btn-form , .popupform .btn{
	background-color: #f39a00 !important;
    color: #fff !important;
}
.form-horizontal .newslatter-btn:hover , .product-item-description__number:hover , .custom-form .btn-form:hover , .popupform .btn:hover{
	background-color: #e28f00 !important;
    color: #fff !important;
}

@media screen and (max-width: 992px) {
	.form-horizontal .form-group{
		min-width: 240px;
	}
	.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
		bottom: 120px;
	}
}
