.social-block {
	padding: 24px 0 48px;
	border-top: 1px solid #e6e6e6;
	text-align: center;
	@include respond-to('small') {
		padding: 24px 0 24px;
	}
	&__item {
		position: relative;
		display: inline-block;
		margin: 0 8px;
		height: 50px;
		width: 50px;
		border-radius: 50%;
		& i {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: #fff;
			font-size: 25px;
		}
		&_face {
			background-color: #3b5998;
		}
		&_whatsup {
			background-color: #29b200;
		}
		&_mail {
			background-color: #2fa3df;
		}
	}
}