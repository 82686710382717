.product-block {}

.product-item-wrap {
	float: right;
	width: 20%;
	padding: 1px;
	@include respond-to('large') {
		width: 33.33%;
	}
	@include respond-to('small') {
		width: 50%;
	}
	@include respond-to('mobile-m') {
		width: 100%;
	}
	&_long {
		width: 40%;
		@include respond-to('large') {
			width: 33.33%;
		}
		@include respond-to('small') {
			width: 50%;
		}
		@include respond-to('mobile-m') {
			width: 100%;
		}
	}
}
.product-item-new {
	&:hover {
		.product-item__img {
			transform: scale3d(1.1, 1.1, 1.1);
		}
		.product-item__overlay {
			opacity: 1;
		}
	}
}

.product-item {
	position: relative;
	display: block;
	overflow: hidden;
	height: 350px;
	width: 100%;
	text-align: center;
	background-position: center;
	background-size: cover;
	@include respond-to('huge') {
		height: 300px;
	}
	// @include respond-to('large') {
	// 	width: 33.33%;
	// }
	@include respond-to('medium') {
		// width: 50%;
		height: 250px;
	}
	@include respond-to('extra-small') {
		width: 100%;
		height: 200px;
	}
	&_long {
		width: 40%;
		@include respond-to('extra-small') {
			width: 100%;
		}
	}
	&:hover {
		.product-item__img {
			transform: scale3d(1.1, 1.1, 1.1);
		}
		.product-item__overlay {
			opacity: 1;
		}
	}
	&__img {
		display: block;
		max-width: 100%;
		height: 100%;
		width: 100%;
		transform: scale3d(1, 1, 1);
		transition: transform 0.15s ease 0s;
	}
	&__overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($color: #000000, $alpha: 0.5);
		opacity: 0;
		transition: opacity 0.15s ease 0s;
	}
	&__icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		& i {
			font-size: 40px;
			color: #fff;
		}
	}
	&__text {
		position: absolute;
		bottom: 35px;
		left: 50%;
		display: block;
		width: 70%;
		transform: translateX(-50%);
		text-align: center;
		color: #ffffff;
		font-size: 18px;
		font-weight: 300;
	}
}





#grid-container {
	width: 100%;
	margin: auto;
	overflow: hidden; 
  }
  
  .grid-item {
	  position: relative;
	opacity: 0;
	float: left; 
	padding: 2px;
	box-sizing: border-box;
  }
   
  .grid-item img {
	width: 100%;
	height: 100%;
  }
  #lg-download{
		display: none;
	}
  .grid-item.loaded {
	opacity: 1;
	transition: opacity .5s;
  }
//   .afterimg:after {
// 	content: "";
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	width: 100%;
// 	height: 100%;
// z-index: 1;

// 	// transform: translate(-50%,-50%);
// 	background-image: url('/~izikbip/assets/images/logo.png');
// 	background-repeat: no-repeat;
//     background-position: center;
// 	background-size: 65%;
// 	opacity: 0.4;
//   } 
	
.lg-actions .lg-next, .lg-actions .lg-prev{
	font-size: 29px;
}
